/* eslint-disable no-magic-numbers */

interface ICharDataArray {
	baseCharCode: number;
	isAccent: boolean;
	isUppercase: boolean;
}

function normalizeCharacters(string: string): string {
	return string.normalize?.('NFKD')?.replace(/[\u0300-\u036F]/g, '');
}

export const getCharDataArray = (text: string): ICharDataArray[] => {
	const handleCaseSensitivity = (
		baseCharCode: number,
		isLowerCase: boolean,
		isAccent = false
	): ICharDataArray => {
		// If caseSensitivity is used, 0.1 is added to the charCode if the letter is uppercase.
		// This is done to push uppercase letters after lowercase letters.
		//return caseSensitive && !isLowerCase ? charCode + 0.1 : charCode;
		return {
			baseCharCode,
			isUppercase: !isLowerCase,
			isAccent,
		};
	};

	return text
		?.toString()
		.split('')
		.map((char) => {
			const lowercaseChar = char.toLowerCase();
			const isLowerCase = char === lowercaseChar;

			const charCode = lowercaseChar.charCodeAt(0);

			/**
			 * æ, ø and å are assigned new char codes since the order otherwise would be å, æ, ø. (Just look at the original char codes).
			 * The new char codes isn't important and I just reused the existing char codes, but changed the order.
			 */
			switch (charCode) {
				case 230: // æ
					return handleCaseSensitivity(229, isLowerCase);
				case 248: // ø
					return handleCaseSensitivity(230, isLowerCase);
				case 229: // å
					return handleCaseSensitivity(248, isLowerCase);
				default: {
					const normalizedCharCode =
						normalizeCharacters(lowercaseChar).charCodeAt(0);

					const hasAccent = normalizedCharCode !== charCode;

					return handleCaseSensitivity(
						normalizedCharCode,
						isLowerCase,
						hasAccent
					);
				}
			}
		});
};
