import {
	isSameOrBefore,
	isSameOrAfter,
	sortByTimeFromKey,
	addDays,
	subtractDays,
	isSameSecond,
	getAsDate,
	convertToDateStringObject,
} from 'utils/datetime/index';
import { CONSTANTS } from '../../projects/quick-view/Resources';
import { IApi } from 'typings/api';
import {
	ICitizenState,
	IModifyDate,
	IModifyActivitiesAndContacts,
	IPeriod,
	IDate,
} from '../types';
import { handleSetCitizenData } from 'api/utils/get-citizen-data-from-sources';
import { actions, setActivitiesAndContacts, setDate } from 'store/main-reducer';
import { getProjectId } from './get-project-id';
import { sortBy } from 'utils/sort/sort-by/sort-by';

export const addNewUniqueAdditionsdByID = <T extends Record<string, unknown>>(
	existing: T[] | null,
	addition: T[],
	shouldSortByTime?: boolean
): T[] => {
	const existingContacts = existing || [];
	const newContacts = addition || [];

	const existingIds = existingContacts.map(({ id }) => id);

	const newItems = newContacts.filter(
		({ id }) => existingIds.indexOf(id) === -1
	);

	const elements = [...existingContacts, ...newItems].filter(Boolean);

	return shouldSortByTime
		? sortByTimeFromKey<T>(elements, 'startTime')
		: elements;
};

export const getActivitiesToDisplay = (
	activities: (IPeriod | IApi['Activity'])[],
	isAllShown: boolean,
	currentMinDate: Date,
	currentMaxDate: Date
): IApi['Activity'][] => {
	const projectId = getProjectId();

	// In worktogether we want to see everything we got.
	if (projectId === 'wt' && isAllShown) {
		return sortBy<IPeriod>({
			list: activities || [],
			name: 'startTime',
			isDescending: false,
			keepNullLast: true,
		});
	}

	return activities.filter(({ startTime, endTime }) => {
		const isValidStartDate =
			!startTime || isSameOrBefore(getAsDate(startTime), currentMaxDate);

		const isValidEndDate =
			!endTime || isSameOrAfter(getAsDate(endTime), currentMinDate);

		return isValidStartDate && isValidEndDate;
	});
};

export const getFormattedMinMaxDates = (
	date: ICitizenState['date'] | IDate
) => {
	return {
		currentMinDate: getAsDate(date.currentMinDate),
		currentMaxDate: getAsDate(date.currentMaxDate),
	};
};

export const createPeriodMap = (
	periods: ICitizenState['periods']
): Record<string, IPeriod[]> => {
	const periodMap: Record<string, IPeriod[]> = {};

	periods?.forEach((period) => {
		// @ts-ignore - Swagger types stemmer ikke helt -.-
		const key = (period.package || '') + ':' + period.originatingSystem;

		if (!periodMap[key]) {
			periodMap[key] = [];
		}

		periodMap[key].push(period);
	});

	Object.keys(periodMap).forEach((key) => {
		if (periodMap.hasOwnProperty(key)) {
			periodMap[key] = sortByTimeFromKey<IPeriod>(
				periodMap[key],
				'startTime'
			);
		}
	});

	return periodMap;
};

export const addActivitiesAndContacts: IModifyActivitiesAndContacts = ({
	citizenId,
	date,
	events,
	periods,
	dispatch,
	contacts,
	notConnectedGoals,
}) => {
	dispatch(
		actions.addActivitiesAndContacts({
			citizenId,
			events,
			periods,
			contacts,
			notConnectedGoals,
			date: convertToDateStringObject(date),
		})
	);
};

export const getModifiedMinMaxDate = (
	date: ICitizenState['date'] | IDate,
	increase: boolean
): Pick<IDate, 'currentMinDate' | 'currentMaxDate'> => {
	let { currentMinDate, currentMaxDate } = getFormattedMinMaxDates(date);

	if (increase) {
		currentMinDate = addDays(currentMinDate, CONSTANTS.TimePeriodInDays);
		currentMaxDate = addDays(currentMaxDate, CONSTANTS.TimePeriodInDays);
	} else {
		currentMinDate = subtractDays(
			currentMinDate,
			CONSTANTS.TimePeriodInDays
		);

		currentMaxDate = subtractDays(
			currentMaxDate,
			CONSTANTS.TimePeriodInDays
		);
	}

	return {
		currentMinDate,
		currentMaxDate,
	};
};

// Only used in quickview
export const modifyDate: IModifyDate = (
	{
		date,
		events,
		contacts,
		periods,
		sourceSystems,
		userOrigin,
		citizenId,
		notConnectedGoals,
		dispatch,
	},
	increase
) => {
	const { currentMinDate, currentMaxDate } = getModifiedMinMaxDate(
		date,
		increase
	);

	const shouldIncrease =
		increase &&
		isSameSecond(currentMinDate, getAsDate(date.maxFutureDateToFetch));

	const shouldDecrease =
		!increase &&
		isSameSecond(currentMaxDate, getAsDate(date.maxPastDateToFetch));

	let maxFutureDateToFetch: Date;
	let maxPastDateToFetch: Date;

	if (shouldIncrease || shouldDecrease) {
		maxFutureDateToFetch = getAsDate(
			shouldIncrease ? currentMaxDate : date.maxFutureDateToFetch
		);

		maxPastDateToFetch = getAsDate(
			shouldDecrease ? currentMinDate : date.maxPastDateToFetch
		);

		dispatch(
			setDate({
				date: convertToDateStringObject({
					currentMinDate,
					currentMaxDate,
					maxFutureDateToFetch,
					maxPastDateToFetch,
				}),
			})
		);

		handleSetCitizenData(
			sourceSystems,
			userOrigin,
			citizenId,
			{
				currentMinDate,
				currentMaxDate,
				maxFutureDateToFetch,
				maxPastDateToFetch,
			},
			dispatch
		);
	} else {
		maxFutureDateToFetch = getAsDate(date.maxFutureDateToFetch);
		maxPastDateToFetch = getAsDate(date.maxPastDateToFetch);

		dispatch(
			setDate({
				date: convertToDateStringObject({
					currentMinDate,
					currentMaxDate,
					maxFutureDateToFetch,
					maxPastDateToFetch,
				}),
			})
		);
	}

	dispatch(
		setActivitiesAndContacts({
			events,
			periods,
			contacts,
			citizenId,
			notConnectedGoals,
		})
	);
};

export const sortContacts = (contacts: IApi['Contact'][]) => {
	contacts.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};
