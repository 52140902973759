import { silentRefresh, getUser } from 'projects/worktogether/login/auth';
import { getURLTimeRange } from 'utils/url';
import { getCitizenIdFromPathName } from './get-citizenid-from-path-name';
import { IRequest } from 'api/types';
import { officeDocx, jsonHeader } from './header-types';
import { getProjectId } from './get-project-id';
import { updateLatestTimeStamp } from 'utils';

interface IHeaderData {
	acceptedHeader?: typeof officeDocx;
}

function getTimeStamp(req?: IRequest): string {
	updateLatestTimeStamp(); // To show that the user is not idle.
	const timeStamp = new Date().getTime();

	if (!req) {
		return '';
	} else if (req.useTimeStamp) {
		return `?ts=${timeStamp}`;
	} else if (req.timeRangeDate) {
		const URLTimeRange = getURLTimeRange(req.timeRangeDate);

		return URLTimeRange + `&ts=${timeStamp}`;
	}

	return '';
}

function getParameterByName(name: string, url?: string) {
	if (!url) {
		url = window.location.href;
	}

	name = name.replace(/[[]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	const parameterIndex = 2;

	if (!results) {
		return null;
	}

	if (!results[parameterIndex]) {
		return '';
	}

	return decodeURIComponent(results[parameterIndex].replace(/\+/g, ' '));
}

const getHeaderQV = (
	method: RequestInit['method'] = 'GET',
	headerData: IHeaderData
) => {
	const headers = new Headers({
		CustomAuthorization: getParameterByName('token') || '',
		'Content-Type': jsonHeader,
	});

	if (headerData.acceptedHeader) {
		headers.set('Accept', headerData.acceptedHeader);
	}

	return {
		method,
		headers,
	};
};

const getHeaderWT = async (
	method: RequestInit['method'] = 'GET',
	headerData: IHeaderData,
	req: IRequest
): Promise<RequestInit> => {
	let user = await getUser();

	if (!user) {
		user = await silentRefresh(); // We log out of still no user
		user = await getUser(); // If silent refresh didn't throw an error, we try again.

		if (!user) {
			throw 'Unable to get user';
		}
	}
	const AuthorizationTokenString = `Bearer ${user.access_token}`;

	const additionalHeader: Record<string, string> = {
		Authorization: AuthorizationTokenString,
	};

	// Due to historical reasons, someone decided to use citizenId as a header instead of a property. Shoot me -.-
	const CitizenId = getCitizenIdFromPathName() || req.citizenId;
	const mockUserId = '0000000000';
	const isMock = CitizenId === mockUserId;

	if (CitizenId && !isMock) {
		additionalHeader.CitizenId = CitizenId;
	} else if (isMock) {
		additionalHeader.Citizenmockid = CitizenId;
	}

	const headers = new Headers({
		...additionalHeader,
		'Content-Type': jsonHeader,
	});

	if (headerData.acceptedHeader) {
		headers.set('Accept', headerData.acceptedHeader);
	}

	return {
		method,
		headers,
	};
};

export const getHeader = async (
	method: RequestInit['method'] = 'GET',
	req: IRequest
): Promise<RequestInit & { queryString: string }> => {
	let queryString = getTimeStamp(req);
	const projectId = getProjectId();
	const headerData: IHeaderData = {};

	if (req?.blobType === 'officeDocx') {
		headerData.acceptedHeader = officeDocx;
	}

	const baseHeader =
		projectId === 'wt'
			? getHeaderWT(method, headerData, req)
			: getHeaderQV(method, headerData);

	const header = await baseHeader;

	if (req) {
		if (method === 'get') {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { useTimeStamp, timeRangeDate, ...data } = req;

			if (Object.keys(data).length) {
				const params = new URLSearchParams(data);

				const parameters = params.toString();
				const alreadyContainsQueryString = queryString.includes('?');

				queryString += `${
					alreadyContainsQueryString ? '&' : '?'
				}${parameters}`;
			}
		} else {
			// @ts-ignore - body findes ikke på get, men på de andre
			header.body = JSON.stringify(req);
		}
	}

	return { ...header, queryString };
};
