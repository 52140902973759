import { ProjectId } from 'typings/app';

const sessionProjectName: 'projectID' = 'projectID';

export const getProjectID = () => {
	if (process.env.NODE_ENV === 'development') {
		return (window.sessionStorage.getItem(sessionProjectName) ||
			process.env.REACT_APP_PROJECT) as ProjectId;
	}

	return process.env.REACT_APP_PROJECT as ProjectId;
};
export const setProjectID = (id: ProjectId) =>
	window.sessionStorage.setItem(sessionProjectName, id);

const previousPathName: 'previousPathName' = 'previousPathName';

export const getPreviousPathName = (id: ProjectId) => {
	const prev = window.sessionStorage.getItem(previousPathName + id);

	if (prev && prev !== '/auth-callback') {
		return prev;
	}

	return '/';
};

export const setPreviousPathName = (id: ProjectId) => {
	const { origin, href } = window.location;
	const fullPathName = href.slice(origin.length);

	window.sessionStorage.setItem(previousPathName + id, fullPathName);
};
