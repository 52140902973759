import { getComparer } from './comparer';

interface ISortBy<T> {
	list: T[];
	isDescending?: boolean;
	name?: string;
	keepNullLast?: boolean;
}

export function sortBy<T>({
	list,
	isDescending,
	name,
	keepNullLast,
}: ISortBy<T>): T[] {
	if (!list?.length || !Array.isArray(list)) return [];

	const comparer = getComparer({
		isDescending: !!isDescending,
		name,
		keepNullLast,
	});

	return list.slice(0).sort(comparer);
}
