/* eslint-disable no-magic-numbers */
import { configureStore } from '@reduxjs/toolkit';
import {
	reduced as mainReducer,
	actions as mainActions,
	findCitizen,
	setPaths as setAppPaths,
	setCitizen,
} from './main-reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiList } from 'api/apiList';
import { getHashAndQuery } from 'hooks/utils/routing';
import { ICitizenDataTypes, ISetCitizenDataPayload } from 'api/types';
import {
	logout,
	silentRefresh,
	getUser,
} from 'projects/worktogether/login/auth';
import { logPageView } from 'utils/logging';
import { validateStatusCode } from './utils/validate-status-code';

const unauthorizedHttpCode = 401;

export const store = configureStore({
	reducer: {
		...mainReducer,
	},
	devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type Actions = typeof mainActions[keyof typeof mainActions];
// | typeof citizenActions[keyof typeof citizenActions];

export const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useStore = () => {
	return useAppSelector(store.getState);
};

export const useStoreScope = <StateScope extends keyof RootState>(
	stateScope: StateScope
): RootState[StateScope] => {
	return useAppSelector(store.getState)[stateScope];
};

export const useStoreAction = () => {
	const dispatch = useAppDispatch();

	return {
		logout,
		silentRefresh,
		findCitizenByCpr: async (cpr: string) => {
			const payload = await apiList.findcitizen.bycpr.post({ cpr });

			if (payload.responseStatusCode === unauthorizedHttpCode) {
				// eslint-disable-next-line
				console.log(await getUser());
			}

			validateStatusCode(
				payload.responseStatusCode,
				payload.exceptionType
			);

			if (payload.data) {
				dispatch(findCitizen(payload.data));
			}

			return payload.data;
		},
		findCitizenById: async (id: string) => {
			const payload = await apiList.findcitizen.byid.post({ id });

			if (payload.data) {
				dispatch(findCitizen(payload.data));
			}
		},
		setPaths: (pathString: string, title?: string) => {
			window.history.pushState({}, title || '', pathString);
			const paths = pathString.split('/').filter(Boolean);

			logPageView(paths[0] || '/', pathString);
			const payload = getHashAndQuery(paths);

			dispatch(setAppPaths(payload));
		},
		setCitizenData: async <DataType extends ICitizenDataTypes, Data>(
			payload: ISetCitizenDataPayload<DataType, Data>
		) => {
			const data = await payload.data;
			const actionPayload = {
				citizenId: payload.citizenId,
				[payload.dataType]: data,
			};

			dispatch(setCitizen(actionPayload));
		},
	};
};
