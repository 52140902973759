/* eslint-disable no-magic-numbers */

// This really really really should be handled in the backend. This is idiotic.
export function validateStatusCode(statusCode: number, exceptionType?: number) {
	if (statusCode < 400) {
		return;
	}

	const exceptionTypeText = exceptionType ? ` (${exceptionType})` : '';
	let errorText = 'Serverfejl';

	if (statusCode == 403) {
		errorText = 'Du har ikke rettighed til at se denne borger';
	}

	if (statusCode < 500) {
		errorText = 'Invalid CPR';
	}

	throw new Error(errorText + exceptionTypeText);
}
