export const KUI_PLAN_DESCRIPTIVE: 'Uddannelsesplan (KUI)' =
	'Uddannelsesplan (KUI)';
export const KUI_PLAN_DESCRIPTIVE_LOWER: 'uddannelsesplan (KUI)' =
	'uddannelsesplan (KUI)';

export const KUI_WITH_CONSENT =
	`${KUI_PLAN_DESCRIPTIVE} med samtykke` as `Uddannelsesplan (KUI) med samtykke`;
export const KUI_WITHOUT_CONSENT =
	`${KUI_PLAN_DESCRIPTIVE} uden samtykke` as `Uddannelsesplan (KUI) uden samtykke`;
export type IKuiMedSamtykke = typeof KUI_WITH_CONSENT;
export type IKuiUdenSamtykke = typeof KUI_WITHOUT_CONSENT;
