import {
	IState,
	IAddActivitiesPayload,
	ISetActivitiesAndContactsPayload,
	ICitizen,
	ICitizenState,
	IAddCitizenInfoPayload,
	IAddMessage,
	ISystemsMessage,
	IConversation,
	IUser,
} from 'api/types';
import { setAdditionalActivitiesAndContacts } from 'api/utils/set-activities';
import { addNewUniqueAdditionsdByID, sortContacts } from 'api/utils/activities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetHashAndQueryRes } from 'hooks/utils/types';
import { RootState } from '.';
import { updatePersonalData } from 'utils/update-data';
import { generateWorkTogetherURL } from 'utils/url';
import { IApi } from 'typings/api';
import { unique } from 'utils/filter';
import Helper from 'projects/quick-view/Services/Helper';
import { initialCitizenState } from './initial-citizen-state';
import { sortBy } from 'utils/sort/sort-by/sort-by';
import { getConsentType, getConsentTypeText } from './utils/get-consent-type';

export const stateScope = 'main';

export const initialState: IState = {
	user: null,
	citizens: {},
	hash: '',
	query: '',
	paths: [],
	isDirty: false,
	promptRouting: false,
	latestTimeStamp: new Date().getTime(),
};

export const slice = createSlice({
	name: stateScope,
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<IUser | null>): void => {
			state.user = action.payload;
		},
		setDirtyState: (state, action: PayloadAction<boolean>): void => {
			state.isDirty = action.payload;
		},
		findCitizen: (
			state,
			action: PayloadAction<IApi['FindCitizenResponse']>
		): void => {
			const { citizen, hasAllData } = action.payload;
			const citizenId = citizen?.citizenId;

			if (!citizenId) return;

			state.citizens[citizenId] = {
				...initialCitizenState,
				...(state.citizens[citizenId] || {}),
				citizen: citizen as ICitizen,
				hasAllData,
			};
		},
		setCitizen: (
			state,
			action: PayloadAction<Partial<ICitizenState>>
		): void => {
			const { citizenId } = action.payload;

			if (!citizenId) return;

			const citizenData = {
				...initialCitizenState,
				...state.citizens[citizenId],
				...action.payload,
				workTogetherUrl: generateWorkTogetherURL(citizenId),
			};

			state.citizens[citizenId] = citizenData;
			state.activeCitizenId = citizenId;
		},
		setActivitiesAndContacts: (
			state,
			action: PayloadAction<ISetActivitiesAndContactsPayload>
		): void => {
			const { citizenId, ...restPayload } = action.payload;

			state.citizens[citizenId] = {
				...state.citizens[citizenId],
				...restPayload,
			};
		},
		setActiveCitizen: (
			state,
			action: PayloadAction<{ activeCitizenId: string }>
		): void => {
			state.activeCitizenId = action.payload.activeCitizenId;
		},
		addActivitiesAndContacts: (
			state,
			action: PayloadAction<IAddActivitiesPayload>
		): void => {
			const { citizenId } = action.payload;

			if (!citizenId) return;

			setAdditionalActivitiesAndContacts<IAddActivitiesPayload>(
				state.citizens[citizenId],
				action.payload
			);
		},
		setPaths: (state, action: PayloadAction<IGetHashAndQueryRes>): void => {
			if (state.isDirty) {
				state.promptRouting = true;

				state.tempRoute = {
					paths: action.payload.paths,
					hash: action.payload.hash,
					query: action.payload.query,
				};

				return;
			}

			// Scroll to top of page
			document.documentElement.scrollTop = 0;

			if (
				action.payload.paths[0] === 'borger' &&
				action.payload.paths[1]
			) {
				state.activeCitizenId = action.payload.paths[1];
			}

			state.paths = action.payload.paths;
			state.hash = action.payload.hash;
			state.query = action.payload.query;

			state.promptRouting = false;
			state.tempRoute = undefined;
		},
		confirmNavigation: (state): void => {
			if (state.tempRoute) {
				state.paths = state.tempRoute.paths;
				state.hash = state.tempRoute.hash;
				state.query = state.tempRoute.query;
			}

			state.isDirty = false;
			state.promptRouting = false;
			state.tempRoute = undefined;
		},
		cancelNavigation: (state): void => {
			state.promptRouting = false;
			state.tempRoute = undefined;
		},

		// Citizen modifiers

		setResponseStatusCode: (
			state,
			action: PayloadAction<Pick<ICitizenState, 'responseStatusCode'>>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].responseStatusCode =
				action.payload.responseStatusCode;
		},
		setFeatureConfiguration: (
			state,
			// @ts-ignore - UserFeatureConfiguration findes ikke i swagger. TODO: tilføj den.
			action: PayloadAction<IApi['UserFeatureConfiguration']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].featureConfiguration =
				action.payload;
		},
		addCitizenInfo: (
			state,
			action: PayloadAction<IAddCitizenInfoPayload>
		): void => {
			if (!state.activeCitizenId) return;

			const updatedCitizen = updatePersonalData({
				currentCitizen: state.citizens[state.activeCitizenId].citizen,
				...action.payload,
			});

			state.citizens[state.activeCitizenId].citizen = updatedCitizen;
		},
		setAccessToApp: (state, action: PayloadAction<boolean>): void => {
			if (!state.activeCitizenId) return;

			state.citizens[
				state.activeCitizenId
			].citizen.hasAccessToCitizenApp = action.payload;
		},
		setDate: (
			state,
			action: PayloadAction<Pick<ICitizenState, 'date'>>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].date = action.payload.date;
		},

		setDataOrigin: (
			state,
			action: PayloadAction<
				Pick<
					ICitizenState,
					'userOrigin' | 'useLogicIdp' | 'sourceSystems'
				>
			>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].userOrigin =
				action.payload.userOrigin;

			state.citizens[state.activeCitizenId].useLogicIdp =
				action.payload.useLogicIdp;

			state.citizens[state.activeCitizenId].sourceSystems =
				action.payload.sourceSystems;
		},
		setConsent: (
			state,
			action: PayloadAction<
				Pick<ICitizenState, 'consentScopes' | 'planType'>
			>
		): void => {
			const { consentScopes, planType } = action.payload;

			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].consentScopes = consentScopes;
			state.citizens[state.activeCitizenId].planType = planType;

			const consentType = getConsentType(planType, consentScopes);

			state.citizens[state.activeCitizenId].consentType = consentType;

			state.citizens[state.activeCitizenId].consentTypeText =
				getConsentTypeText(consentType);

			const hasConsent = Helper.isCorrectConsentState(
				consentScopes,
				planType
			);

			state.citizens[state.activeCitizenId].hasConsent = hasConsent;

			state.citizens[state.activeCitizenId].hasConsentWithKuiConsidered =
				hasConsent && consentType !== 'kuiWithoutConsent';
		},
		deleteConsent: (state): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].consentScopes = [];
			state.citizens[state.activeCitizenId].planType = '';

			const consentType = getConsentType('', []);

			state.citizens[state.activeCitizenId].consentType = consentType;

			state.citizens[state.activeCitizenId].consentTypeText =
				getConsentTypeText(consentType);

			const hasConsent = Helper.isCorrectConsentState([], '');

			state.citizens[state.activeCitizenId].hasConsent = hasConsent;

			state.citizens[state.activeCitizenId].hasConsentWithKuiConsidered =
				hasConsent && consentType !== 'kuiWithoutConsent';
		},
		setConsentHistory: (
			state,
			action: PayloadAction<IApi['LastConsentStatus']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].consentHistory =
				action.payload;
		},
		setManuallyAddedContacts: (
			state,
			action: PayloadAction<IApi['Contact'][]>
		): void => {
			if (!state.activeCitizenId) return;

			const payload = action.payload?.length ? action.payload : [];

			const contacts = addNewUniqueAdditionsdByID<IApi['Contact']>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state.citizens[state.activeCitizenId].contacts as any,
				payload
			);

			sortContacts(contacts);

			state.citizens[state.activeCitizenId].contacts = contacts;
		},
		updateContactAppVisibility: (
			state,
			action: PayloadAction<
				Pick<IApi['Contact'], 'id' | 'originatingSystem' | 'appVisible'>
			>
		): void => {
			const { id, originatingSystem, appVisible } = action.payload;

			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].contacts?.forEach(
				(contact) => {
					if (
						contact.id !== id ||
						contact.originatingSystem !== originatingSystem
					) {
						return;
					}

					contact.appVisible = appVisible;
				}
			);
		},
		addContact: (state, action: PayloadAction<IApi['Contact']>): void => {
			if (!state.activeCitizenId) return;

			const contact = action.payload;
			const activeCitizen = state.citizens[state.activeCitizenId];
			const contacts = activeCitizen.contacts || [];

			activeCitizen.contacts = sortBy({
				list: [...contacts, contact],
				name: 'name',
			});
		},
		removeContact: (
			state,
			action: PayloadAction<Pick<IApi['Contact'], 'id'>>
		): void => {
			if (!state.activeCitizenId) return;

			const activeCitizen = state.citizens[state.activeCitizenId];

			activeCitizen.contacts = activeCitizen.contacts?.filter(
				(contact) => contact.id !== action.payload.id
			);
		},
		updateMessage: (
			state,
			action: PayloadAction<
				IConversation & {
					reply?: IConversation['replies'][0];
				}
			>
		): void => {
			if (!state.activeCitizenId) return;

			const updatedConversation = action.payload;

			const beskeder = state.citizens[state.activeCitizenId]?.beskeder;

			if (!beskeder?.conversations) return;

			const index = beskeder.conversations.findIndex(
				(conversation) => conversation.id === updatedConversation.id
			);

			const isDateUpdated =
				beskeder.conversations[index]?.updatedOn !==
				updatedConversation.updatedOn;

			if (isDateUpdated) {
				if (index !== -1) {
					delete beskeder.conversations[index];
				}

				beskeder.conversations.unshift(updatedConversation);
			} else {
				beskeder.conversations[index] = updatedConversation;
			}
		},
		setSystemMessages: (
			state,
			action: PayloadAction<Pick<ICitizenState, 'systemsMessages'>>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsMessages = {
				...state.citizens[state.activeCitizenId].systemsMessages,
				...action.payload.systemsMessages,
			};
		},
		addSystemMessage: (
			state,
			action: PayloadAction<
				ISystemsMessage & Pick<IAddMessage, 'systemType'>
			>
		): void => {
			const { text, type, systemType } = action.payload;

			if (!systemType || !state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsMessages = {
				...state.citizens[state.activeCitizenId].systemsMessages,
				[systemType]: { text, type },
			};
		},
		removeSystemMessage: (
			state,
			action: PayloadAction<IAddMessage['systemType']>
		): void => {
			if (!action.payload || !state.activeCitizenId) return;

			delete state.citizens[state.activeCitizenId].systemsMessages[
				action.payload
			];
		},
		clearSystemErrorMessages: (state): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsLoading = [];
			state.citizens[state.activeCitizenId].systemsWithoutData = [];
			state.citizens[state.activeCitizenId].systemsWithErrors = [];
			state.citizens[state.activeCitizenId].systemsMessages = {};
		},
		addSystemLoading: (
			state,
			action: PayloadAction<IApi['SystemType']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsLoading = [
				...(state.citizens[state.activeCitizenId].systemsLoading || []),
				action.payload,
			];
		},
		removeSystemLoading: (
			state,
			action: PayloadAction<IApi['SystemType']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsLoading = (
				state.citizens[state.activeCitizenId].systemsLoading || []
			).filter((system) => system !== action.payload);
		},
		addSystemWithoutData: (
			state,
			action: PayloadAction<IApi['SystemType']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsWithoutData = [
				...(state.citizens[state.activeCitizenId].systemsWithoutData ||
					[]),
				action.payload,
			].filter(unique);
		},
		addSystemWithErrors: (
			state,
			action: PayloadAction<IApi['SystemType']>
		): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].systemsWithErrors = [
				...(state.citizens[state.activeCitizenId].systemsWithErrors ||
					[]),
				action.payload,
			].filter(unique);
		},
		getInitialDataFailed: (state): void => {
			if (!state.activeCitizenId) return;

			state.citizens[state.activeCitizenId].responseStatusCode = 500;
			state.citizens[state.activeCitizenId].apiFailed = true;
		},
		updateFaellesmaal: (
			state,
			action: PayloadAction<IApi['Goal']>
		): void => {
			if (
				!state.activeCitizenId ||
				!state.citizens[state.activeCitizenId].commonPlan
			) {
				return;
			}

			state.citizens[state.activeCitizenId].commonPlan = {
				...state.citizens[state.activeCitizenId].commonPlan,
				commonGoal: action.payload,
			};
		},
		updatePartialGoal: (
			state,
			action: PayloadAction<IApi['PartialGoal'] & { isOpret: boolean }>
		): void => {
			if (
				!state.activeCitizenId ||
				!state.citizens[state.activeCitizenId].commonPlan
			) {
				return;
			}

			const { isOpret, ...newDelmaal } = action.payload;
			const partialGoals =
				state.citizens[state.activeCitizenId].commonPlan
					?.partialGoals || [];

			if (!isOpret) {
				state.citizens[state.activeCitizenId].commonPlan = {
					...state.citizens[state.activeCitizenId].commonPlan,
					partialGoals: partialGoals.map((delmaal) => {
						if (delmaal.id !== action.payload.id) {
							return delmaal;
						}

						return newDelmaal;
					}),
				};
			} else {
				state.citizens[state.activeCitizenId].commonPlan = {
					...state.citizens[state.activeCitizenId].commonPlan,
					partialGoals: [newDelmaal, ...partialGoals],
				};
			}
		},
		setOpgaver: (state, action: PayloadAction<IApi['GoalTask'][]>) => {
			if (
				!state.activeCitizenId ||
				// Vi vil kun have den til at køre en gang når data er klar.
				state.citizens[state.activeCitizenId].opgaver.length > 0
			) {
				return;
			}

			state.citizens[state.activeCitizenId].opgaver = action.payload;
		},
		updateOpgave: (state, action: PayloadAction<IApi['GoalTask']>) => {
			if (!state.activeCitizenId) {
				return;
			}
			const index = state.citizens[
				state.activeCitizenId
			].opgaver.findIndex((opgave) => opgave.id === action.payload.id);

			if (index < 0) {
				// Opgave findes ikke, og derfor tilføjer vi den
				state.citizens[state.activeCitizenId].opgaver.unshift(
					action.payload
				);
			} else {
				state.citizens[state.activeCitizenId].opgaver[index] =
					action.payload;
			}
		},
		setKuiChoices: (
			state,
			action: PayloadAction<IApi['PlanPdfChoices']['choices']>
		) => {
			state.kuiPdfChoices = action.payload;
		},
		addParentIds: (state, action: PayloadAction<string[]>) => {
			state.citizens[state.activeCitizenId].parentIds = [
				...(state.citizens[state.activeCitizenId].parentIds || []),
				...action.payload,
			];
		},
		updateLatestTimeStamp: (state) => {
			/*
				We use this to check if the user is idle. E.g. If the user behaviour hasn't contacted the server for a given pediod.
				Hovever, this situation can occur, if the user is writing a long text, and doesn't click save.
				In that case, we need to check if they are still there. Otherwise we will need to log them out for security reasons.
			*/

			state.latestTimeStamp = new Date().getTime();
		},
	},
});

export const { reducer, actions } = slice;

export const {
	setUser,
	setDirtyState,
	findCitizen,
	setCitizen,
	setActiveCitizen,
	addActivitiesAndContacts,
	setPaths,
	confirmNavigation,
	cancelNavigation,
	setResponseStatusCode,
	setFeatureConfiguration,
	addCitizenInfo,
	setAccessToApp,
	setDate,
	setActivitiesAndContacts,
	setDataOrigin,
	setConsent,
	deleteConsent,
	setConsentHistory,
	setManuallyAddedContacts,
	updateContactAppVisibility,
	addContact,
	removeContact,
	updateMessage,
	setSystemMessages,
	addSystemMessage,
	removeSystemMessage,
	addSystemLoading,
	removeSystemLoading,
	addSystemWithoutData,
	addSystemWithErrors,
	clearSystemErrorMessages,
	getInitialDataFailed,
	updateFaellesmaal,
	updatePartialGoal,
	updateOpgave,
	setOpgaver,
	setKuiChoices,
	addParentIds,
	updateLatestTimeStamp,
} = actions;

export const getState = (state: RootState): IState => state[stateScope];

export const reduced = { [stateScope]: reducer };
