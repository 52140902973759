export const getEnvironmentSettings = () => {
	const isProduction =
		process.env.REACT_APP_ENVIRONMENT === 'production' ||
		process.env.NODE_ENV === 'production';

	return {
		releaseVersion: process.env.REACT_APP_NOVA_LINK_RELEASE_VERSION,
		production: isProduction,
		apiUrl: process.env.REACT_APP_WORKTOGETHER_URL,
		appInsights: {
			instrumentationKey:
				process.env.REACT_APP_APPLICATIONINSIGHTS_INSTRUMENTATION_KEY,
		},
		logicAuth: {
			authority: process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_AUTHORITY,
			client_id: process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_CLIENT_ID,
			redirect_uri:
				process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_REDIRECT_URI,
			post_logout_redirect_uri:
				process.env
					.REACT_APP_NOVA_LINK_LOGIC_AUTH_POST_LOGOUT_REDIRECT_URI,
			response_type:
				process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_RESPONSE_TYPE,
			scope: process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_SCOPE,
			filterProtocolClaims: true,
			loadUserInfo: false,
			automaticSilentRenew: true,
			silent_redirect_uri:
				process.env.REACT_APP_NOVA_LINK_LOGIC_AUTH_SILENT_REDIRECT_URI,
			silentRequestTimeout: '30000',
			accessTokenExpiringNotificationTime: 180,
		},
		dialogIntegration: {
			uvvej_kui:
				process.env
					.REACT_APP_NOVA_LINK_DIALOG_INTEGRATION_UVVEJ_KUI_URL,
		},
	};
};
