import { getProjectID } from 'utils/session-cache';
import { ProjectId } from 'typings/app';

export const getProjectId = (): ProjectId => {
	if (process.env.NODE_ENV === 'development') {
		return getProjectID();
	}

	return process.env.REACT_APP_PROJECT as ProjectId;
};
