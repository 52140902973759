export function kebabCaseToCamelCase(kebabString: string): string {
	if (!kebabString) return kebabString;

	const stringArray = kebabString.split('-');

	const camelArray = stringArray.map((item, index) =>
		index > 0
			? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
			: item.toLowerCase()
	);

	const camelString = camelArray.join('');

	return camelString;
}

export function kebabCaseToPascalCase(kebabString: string): string {
	if (!kebabString) return kebabString;

	const stringArray = kebabString.split('-');

	const pascalArray = stringArray.map(
		(item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
	);

	const pascalString = pascalArray.join('');

	return pascalString;
}
