/* eslint-disable no-console */
import {
	User,
	UserManager,
	UserManagerSettings,
	WebStorageStateStore,
	Log,
} from 'oidc-client';
import { store } from 'store';
import { setUser } from 'store/main-reducer';
import { getEnvironmentSettings } from 'environments';
import { logException, logApi } from 'utils/logging';

Log.logger = console;

// Stierne kommer fra manifestet som ligger i azure
// https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Manifest/appId/b780d9b7-e52c-4e68-9a74-ffd944197661/isMSAApp/
// Hvis link ikke virker: Gå til azure portal, find "Azure AD B2C App" - her kan du se manifestet.
// Ved en SPA bør vi bruge Implicit Flow, som p.t. står til at være false.
export function getClientSettings(): UserManagerSettings {
	const environment = getEnvironmentSettings();

	return {
		authority: environment.logicAuth.authority,
		client_id: environment.logicAuth.client_id,
		redirect_uri: environment.logicAuth.redirect_uri,
		post_logout_redirect_uri:
			environment.logicAuth.post_logout_redirect_uri,
		response_type: environment.logicAuth.response_type,
		scope: environment.logicAuth.scope,
		filterProtocolClaims: environment.logicAuth.filterProtocolClaims,
		loadUserInfo: environment.logicAuth.loadUserInfo,
		automaticSilentRenew: environment.logicAuth.automaticSilentRenew,
		silent_redirect_uri: environment.logicAuth.silent_redirect_uri,
		silentRequestTimeout: environment.logicAuth.silentRequestTimeout,
		accessTokenExpiringNotificationTime:
			environment.logicAuth.accessTokenExpiringNotificationTime,
		userStore: new WebStorageStateStore({ store: window.localStorage }),
		staleStateAge: 1800, // half an hour
	};
}

export type ILogOut = (manager?: UserManager) => void;

/**
 *  OIDC types - Not prefixed with I
 * */
export { User };

function getManager(): UserManager {
	return new UserManager(getClientSettings());
}

const manager = getManager();

/**
 *  Logout the and clear local- and session storage.
 * */
export async function logout(manager?: UserManager): Promise<void> {
	if (!manager) {
		manager = getManager();
	}

	await manager.signoutRedirect({
		extraQueryParams: { client_id: manager.settings.client_id },
	});

	manager.clearStaleState();
	manager.removeUser();

	window.localStorage.clear();
	window.sessionStorage.clear();
}

function handleErrorAndLogout(error: Error) {
	console.error(error);
	logException(error);
	logout();
}

export function startOIDCManagerEventListeners() {
	manager.startSilentRenew();

	// When access token is about to expire, we need to get a new one.
	manager.events.addAccessTokenExpired(() => {
		const handleAsync = async () => {
			// signinSilent makes a callback that returns a user.
			const user = await manager
				.signinSilent()
				.catch(handleErrorAndLogout);

			if (user) {
				manager.storeUser(user);
			} else {
				logout();
			}
		};

		const timeout = 10000;

		setTimeout(handleAsync, timeout);
	});
}

/**
 *  Get the user data. If no user is found, we redirect to logic signin page,
 *  which afterwards will redirect to /auth-callback.
 *  This path will return the user using completeLogin() from auth.ts
 * */
export async function getUser(): Promise<User | void> {
	const user = await manager.getUser();

	if (user) {
		// eslint-disable-next-line no-magic-numbers
		const currentTime = new Date().getTime() / 1000;

		if (user.expires_at && user.expires_at > currentTime) {
			// dummy api path for debugging
			logApi('fe/worktogether/login', user.profile);

			store.dispatch(
				// @ts-ignore
				setUser({ name: user.profile.name })
			);

			return user;
		}
	}

	// If no user - we redirect to logic signin page
	return manager.signinRedirect({ state: '/' });
}

/**
 *  If we are redirected to /auth-callback - we complete the login, and save the user
 * */
export async function completeLogin(): Promise<void> {
	const user = await manager
		.signinRedirectCallback()
		.catch(handleErrorAndLogout);

	if (user) {
		manager.storeUser(user);

		return;
	}

	// if no user, we redirect to logic login
	return manager.signinRedirect({ state: '/' });
}

export async function silentRefresh(): Promise<void> {
	const response = await manager.signinSilent().catch(handleErrorAndLogout);

	if (response) {
		// eslint-disable-next-line
		console.info('Token refreshed');
	} else {
		const error = new Error('Token could not be refreshed');

		handleErrorAndLogout(error);
	}
}

export function silentCallback() {
	return manager
		.signinSilentCallback()
		.then(() => {
			// tslint:disable-next-line:no-console
			console.info('Token refreshed.');
		})
		.catch(handleErrorAndLogout);
}
