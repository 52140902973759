/* eslint-disable max-params */
import { IDate, ICitizenState } from '../types';
import { addActivitiesAndContacts } from '../utils/activities';
import {
	addSystemLoading,
	removeSystemLoading,
	addSystemWithoutData,
	addSystemWithErrors,
	clearSystemErrorMessages,
	addParentIds,
} from 'store/main-reducer';
import { IApi } from 'typings/api';
import { AppDispatch } from 'store';
import { fetchy } from 'api';

export const handleSetCitizenData = async (
	systems: IApi['Connector'][],
	userOrigin: ICitizenState['userOrigin'],
	citizenId: string,
	date: IDate,
	dispatch: AppDispatch
) => {
	dispatch(clearSystemErrorMessages());

	// Runs over every systemType i.e. unoUng, Uvvej, Momentum etc.. and fetches data form each. Afterwards we combine them into lists
	// 🤮
	systems.forEach((system) => {
		if (!system || !system.connectorUrl || !system.systemType) return;

		const isOriginator = userOrigin === system.systemType;
		// Kan ikke bruge apiList her p.t.

		// Notify UI that we fetch data from systemType
		if (system.systemType) {
			dispatch(addSystemLoading(system.systemType));
		}

		fetchy<IApi['ActivitiesContainer']>(system.connectorUrl, 'get', {
			timeRangeDate: date,
		})
			.then((systemsResponse) => {
				if (systemsResponse) {
					const { data, noData } = systemsResponse;
					const {
						events,
						periods,
						contacts,
						parents,
						citizen,
						notConnectedGoals,
					} = data || {};

					const contactsAndParents = contacts
						? contacts.concat(parents || [])
						: parents || [];

					if (parents?.length) {
						// Elendig datastruktur fra start. Vi fik pludselig brug for at isolere parents under kontakter 🤮
						const parentIds = parents.map(({ id }) => id);

						dispatch(addParentIds(parentIds));
					}

					if (noData || (!events?.length && !periods?.length)) {
						if (system.systemType) {
							const hasError =
								systemsResponse.responseStatusCode.toString()[0] !==
								'2';

							if (hasError) {
								dispatch(
									addSystemWithErrors(system.systemType)
								);
							} else {
								dispatch(
									addSystemWithoutData(system.systemType)
								);
							}
						}
					}

					// Because data from Nexus is slow, we want to add data to the UI as we get them. And not use a loading spinner.
					addActivitiesAndContacts({
						citizenId,
						date,
						events: events || [],
						periods: periods || [],
						contacts: contactsAndParents,
						notConnectedGoals: (notConnectedGoals || []).map(
							(goal) => ({
								...goal,
								originatingSystem: system.systemType,
							})
						),
						dispatch,
					});

					// Since we get data from different sources, we will update citizen data with missing pieces...
					if (citizen) {
						dispatch({
							type: 'addCitizenInfo',
							payload: {
								citizen,
								isOriginator,
								citizenId,
							},
						});
					}

					if (system.systemType) {
						dispatch(removeSystemLoading(system.systemType));
					}
				}
			})
			.catch(() => {
				if (system.systemType) {
					dispatch(addSystemWithErrors(system.systemType));
					dispatch(removeSystemLoading(system.systemType));
				}
			});
	});
};
