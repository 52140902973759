import { DEFAULT_NAMES } from '../projects/quick-view/Resources';
import { ICitizen, IAddCitizenInfoPayload } from '../api/types';

interface IUpdatePersonalData extends IAddCitizenInfoPayload {
	currentCitizen: ICitizen;
}

type citizenIdentityType =
	| ICitizen['cpr']
	| ICitizen['name']
	| ICitizen['mobilePhone'];

const existsAndIsFromOriginator = (
	isOriginator: boolean,
	value?: citizenIdentityType
) => isOriginator && !!value;
const doesNotExists = (value?: citizenIdentityType) => !value?.length;

export const updatePersonalData = ({
	currentCitizen,
	citizen,
	isOriginator,
	citizenId,
}: IUpdatePersonalData): ICitizen => {
	if (!citizen && currentCitizen) {
		return currentCitizen;
	}

	citizen = citizen
		? citizen
		: {
			cpr: '',
			name: DEFAULT_NAMES.CitizenName,
			mobilePhone: '',
		  };

	return {
		cpr:
			doesNotExists(currentCitizen.cpr) ||
			existsAndIsFromOriginator(isOriginator, citizen.cpr)
				? citizen.cpr
				: currentCitizen.cpr,

		name: doesNotExists(currentCitizen.name)
			? // || existsAndIsFromOriginator(isOriginator, citizen.name) // Lad Logic CPR få præcedens
			  citizen.name
			: currentCitizen.name,

		mobilePhone:
			doesNotExists(currentCitizen.mobilePhone) ||
			existsAndIsFromOriginator(isOriginator, citizen.mobilePhone)
				? citizen.mobilePhone
				: currentCitizen.mobilePhone,

		citizenId: citizenId,
		hasAccessToCitizenApp: currentCitizen.hasAccessToCitizenApp,
	};
};
