export function getCitizenIdFromPathName() {
	/*
		Due to historical reasons, someone desided to use citizenId as a header instead of a property. Shoot me -.-
		/borger/2566221784/kalender
	*/

	const citizenIdIndex = 2;

	const id = window.location.pathname.split('/')[citizenIdIndex];

	if (Number.isInteger(parseInt(id))) {
		return id;
	}
}
