import { ICitizenState } from 'api/types';
import { CONSTANTS } from '../projects/quick-view/Resources';
import { addDays, getCachedDateKeyForCalendar } from 'utils/datetime/index';

const now = new Date();
const maxFutureDate = addDays(now, CONSTANTS.TimePeriodInDays);

export const initialCitizenState: ICitizenState = {
	citizen: {
		name: '',
		cpr: '',
		citizenId: '',
		mobilePhone: '',
	},
	opgaver: [],
	events: [],
	periods: [],
	notConnectedGoals: [],
	contacts: null,
	errorMessages: {},
	date: {
		maxPastDateToFetch: now.toISOString(),
		maxFutureDateToFetch: maxFutureDate.toISOString(),
		currentMaxDate: maxFutureDate.toISOString(),
		currentMinDate: now.toISOString(),
	},
	cachedMonths: [getCachedDateKeyForCalendar(now)],
	firstFetch: true,
	userOrigin: 'Unknown',
	responseStatusCode: 0,
	apiFailed: false,
	systemsMessages: {},
	useLogicIdp: false,
	consentScopes: [],
	planType: '',
	workTogetherUrl: null,
	sourceSystems: [],
	systemsLoading: [],
	systemsWithoutData: [],
	systemsWithErrors: [],
};
