/* 
/  DO NOT SORT POLYFILL LIB
/  Order matters for IE
/  It needs to be imported before react standard libraries
*/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/includes';

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';

import React from 'react';
import ReactDOM from 'react-dom';
import { ProjectId } from 'typings/app';
import { getProjectID, setProjectID } from 'utils/session-cache';

import './index.scss';
import { silentCallback } from 'projects/worktogether/login/auth';

// Takes an Id and starts the selected app.
async function startApp(projectID: ProjectId) {
	// In src/projects/worktogether/login/auth.ts we redicret to this url after we complete login
	if (window.location.pathname !== '/auth-callback') {
		// In the WT app, we use this the set the initial paths after the login redirection.
		window.sessionStorage.setItem(
			'initialPathName',
			window.location.pathname
		);
	}

	// Used when refresh access token
	if (window.location.pathname === '/silent-refresh') {
		return silentCallback();
	}

	// Let's us jump between project while running development. Here we set the initial projectId
	if (process.env.NODE_ENV === 'development') {
		setProjectID(projectID);
	}

	// A better solution would be to use module federation or micro frontends. But for now we keep it simple.
	switch (projectID) {
		case 'qv': {
			return (await import('projects/quick-view')).default();
		}
		case 'wt': {
			return (await import('projects/worktogether')).default();
		}
	}
}

// Here we render the buttons that let us jump between project while running development.
if (process.env.NODE_ENV === 'development') {
	window.startApp = startApp;
	window.isDev = true;

	import('ui/dev-utils/shift-app-buttons').then(({ ShiftAppButton }) => {
		ReactDOM.render(
			<ShiftAppButton idList={['wt', 'qv']} onClick={window.startApp} />,
			document.getElementById('util')
		);
	});
}

// Starts the selected app from the selected Id 'wt' og 'qv'
startApp(getProjectID());
