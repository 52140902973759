import { AlertType } from 'projects/quick-view/Components/Alert/typings';

export const ERROR_MESSAGES = {
	NoDataToDisplay: 'Ingen aktiviteter i den valgte periode.',
	ApiFailed:
		'Grundet systemfejl er der i øjeblikket problemer med at få adgang til oplysningerne.',
	LackOfConsent:
		'Der er ikke registeret et samtykke til datadeling i Nova Link på denne borger.',
};

export const MESSAGES_TYPES: Record<string, AlertType> = {
	Success: 'success',
	Info: 'info',
	Warning: 'warning',
	Danger: 'danger',
};

export const SYSTEMS = {
	AllSystems: 'ALL_SYSTEMS',
	Dubu: 'DUBU',
	Momentum: 'MOMENTUM',
	Fasit: 'FASIT',
	Nexus: 'NEXUS',
	Uvvej: 'UVVEJ',
	NovaESDH: 'NOVA_ESDH',
	NovaLinkTestSystem: 'NOVA_LINK_TEST_SYSTEM',
	Bot: 'BOT',
	NovaLink: 'NOVA LINK',
	Unoung: 'UNOUNG',
};

export const DATA_KIND = {
	Activities: 'Aktiviteter',
	Contacts: 'Kontakter',
};

export const URLS = {
	GoogleMaps: 'https://google.com/maps/place/', // TODO: Burde det ikke være OpenStreetMap?
	ApiSystemsPath: 'api/system/',
	FindCitizenByIdPath: 'api/findCitizen/byId',
	UpdateAccessToCitizenApp: 'api/Citizen/updateAccessToCitizenApp',
	UserFeatureConfiguration: 'api/userConfiguration',
	ConsentHistory: 'api/consent/history/last',
	GetManuallyAddedContacts: 'api/contacts/getManuallyAddedContacts',
};

export const CONSTANTS = {
	TimePeriodInDays: 30,
	MsInDay: 86400000,
};

export const HINTS = {
	OpenWorkTogether: 'Åbn i Samarbejdsrummet',
	chevronLeftTooltip: `Gå ${CONSTANTS.TimePeriodInDays} dage tilbage`,
	chevronRightTooltip: `Gå ${CONSTANTS.TimePeriodInDays} dage frem`,
	refreshTooltip: `Opdater data`,
};
export const CUSTOM_STYLES = {
	CustomIconButton: {
		StandardBackground: '#ECEFF1',
		OnHoverBackground: '#CCCCCC',
	},
};

export const DEFAULT_NAMES = {
	CitizenName: 'Ukendt',
};
