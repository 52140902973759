import { ICitizenState } from 'api/types';
import { KUI_WITH_CONSENT, KUI_WITHOUT_CONSENT } from 'utils/name-variables';

export const getConsentType = (
	planType: ICitizenState['planType'],
	consentScopes: ICitizenState['consentScopes']
): ICitizenState['consentType'] => {
	if (!planType || !consentScopes?.length) return 'noConsent';
	if (planType.toLowerCase() !== 'kui') return 'normalConsent';

	if (consentScopes.includes('NOT_REQUIRED')) {
		return 'kuiWithoutConsent';
	}

	return 'kuiWithConsent';
};

export const getConsentTypeText = (
	consentType: ICitizenState['consentType']
): ICitizenState['consentTypeText'] => {
	switch (consentType) {
		case 'kuiWithConsent':
			return KUI_WITH_CONSENT;
		case 'kuiWithoutConsent':
			return KUI_WITHOUT_CONSENT;
		case 'normalConsent':
			return 'Samtykke';
		case 'noConsent':
			return 'Uden samtykke';
	}
};
