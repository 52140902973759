class Helper {
	static getParameterByName(name, url) {
		if (!url) {
			url = window.location.href;
		}

		name = name.replace(/[[]]/g, '\\$&');
		const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(url);

		if (!results) {
			return null;
		}

		if (!results[2]) {
			return '';
		}

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	/**
	 * get a nicely formatted cpr number,
	 * ex: 120478-1179
	 *
	 * @param cpr 1204781179
	 * @returns {string}
	 */
	static formatCpr(cpr) {
		return cpr && cpr.length === 10
			? cpr.substr(0, 6) + '-' + cpr.substr(6)
			: cpr;
	}

	static isCorrectConsentState(consentScopes, planType) {
		if (planType === 'Kui' && consentScopes && consentScopes.length > 0) {
			return true;
		}

		return (
			consentScopes &&
			consentScopes.length > 0 &&
			!consentScopes.includes('NOT_REQUIRED')
		);
	}

	static shouldShowGrouppedPeriods(groupKey) {
		return groupKey.split(':')[0].length > 0;
	}
}

export default Helper;
