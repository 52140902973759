import { IApi } from '../typings/api';
import { fetchy } from './index';
import { IRequest } from './types';

/* ------------------------------------------------------------------
   ------ Rediger kun ved fejl i Swagger ------
   ------ Denne fil er autogenereret fra build-tools/build-api
   ------ Kan opdateres med: npm run apiBuilder
   ------------------------------------------------------------------
  */

/**
 * API er et stort typestærkt object med alle api paths som vi har i Swagger
 */

export const apiList = {
	auth: {
		token: {
			post: (req: IRequest<IApi['TokenRequest']>) =>
				fetchy<IApi['TokenResponse']>('/auth/token', 'post', req),
		},
	},
	citizen: {
		updateaccesstocitizenapp: {
			post: (req: IRequest<IApi['CitizenMobileAppAccessRequest']>) =>
				fetchy<IRequest>(
					'/api/citizen/updateaccesstocitizenapp',
					'post',
					req
				),
		},
	},
	commonplan: {
		task: {
			post: (req: IRequest<IApi['GoalTaskSaveRequest']>) =>
				fetchy<IRequest>('/api/commonplan/task', 'post', req),
			put: (req: IRequest<IApi['GoalTaskUpdateRequest']>) =>
				fetchy<IRequest>('/api/commonplan/task', 'put', req),
			delete: (req: IRequest<IApi['DeleteTaskRequest']>) =>
				fetchy<IRequest>('/api/commonplan/task', 'delete', req),
		},
		taskpartial: {
			put: (req: IRequest<IApi['TaskStatusChangeRequest']>) =>
				fetchy<IRequest>('/api/commonplan/taskpartial', 'put', req),
		},
		partialgoal: {
			post: (req: IRequest<IApi['PartialGoalSaveRequest']>) =>
				fetchy<IRequest>('/api/commonplan/partialgoal', 'post', req),
			put: (req: IRequest<IApi['PartialGoalUpdateRequest']>) =>
				fetchy<IRequest>('/api/commonplan/partialgoal', 'put', req),
			delete: (req: IRequest<IApi['DeletePartialGoalRequest']>) =>
				fetchy<IRequest>('/api/commonplan/partialgoal', 'delete', req),
		},
		commongoal: {
			post: (req: IRequest<IApi['CommonGoalSaveRequest']>) =>
				fetchy<IRequest>('/api/commonplan/commongoal', 'post', req),
			put: (req: IRequest<IApi['CommonGoalUpdateRequest']>) =>
				fetchy<IRequest>('/api/commonplan/commongoal', 'put', req),
		},
		get: (req?: IRequest) =>
			fetchy<IApi['CommonPlan']>('/api/commonplan', 'get', req),
		citizentasks: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/commonplan/citizentasks', 'get', req),
		},
		plantype: {
			get: (req?: IRequest) =>
				fetchy<IApi['CommonPlanPlanType']>(
					'/api/commonplan/plantype',
					'get',
					req
				),
			post: (req: IRequest<IApi['PlanTypeSaveRequest']>) =>
				fetchy<IApi['CommonPlanPlanType']>(
					'/api/commonplan/plantype',
					'post',
					req
				),
			put: (req: IRequest<IApi['PlanTypeUpdateRequest']>) =>
				fetchy<IApi['CommonPlanPlanType']>(
					'/api/commonplan/plantype',
					'put',
					req
				),
		},
		docx: {
			post: (req: IRequest<IApi['DownloadCommonPlanDocumentRequest']>) =>
				fetchy<IRequest>('/api/commonplan/docx', 'post', req),
		},
		citizens: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/commonplan/citizens', 'get', req),
		},
	},
	consent: {
		get: (req?: IRequest) =>
			fetchy<IApi['CitizenConsentInformation']>(
				'/api/consent',
				'get',
				req
			),
		post: (req: IRequest<IApi['CreateConsentRequest']>) =>
			fetchy<IRequest>('/api/consent', 'post', req),
		delete: (req: IRequest) =>
			fetchy<IRequest>('/api/consent', 'delete', req),
		createconsentnotrequired: {
			post: (req: IRequest<IApi['CreateNotRequiredConsentRequest']>) =>
				fetchy<IRequest>(
					'/api/consent/createconsentnotrequired',
					'post',
					req
				),
		},
		expandconsent: {
			post: (req: IRequest<IApi['ExpandConsentRequest']>) =>
				fetchy<IRequest>('/api/consent/expandconsent', 'post', req),
		},
		history: {
			last: {
				get: (req?: IRequest) =>
					fetchy<IApi['LastConsentStatus']>(
						'/api/consent/history/last',
						'get',
						req
					),
			},
		},
		getcitizensforconsentrevokeandnotifications: {
			get: (req?: IRequest) =>
				fetchy<IApi['ExpiringConsentsList']>(
					'/api/consent/getcitizensforconsentrevokeandnotifications',
					'get',
					req
				),
		},
		sendconsentrevokemail: {
			post: (req: IRequest) =>
				fetchy<IRequest>(
					'/api/consent/sendconsentrevokemail',
					'post',
					req
				),
		},
		consentexpired: {
			post: (req: IRequest) =>
				fetchy<IRequest>('/api/consent/consentexpired', 'post', req),
		},
		consentexpiringnotification: {
			post: (req: IRequest) =>
				fetchy<IRequest>(
					'/api/consent/consentexpiringnotification',
					'post',
					req
				),
		},
	},
	contacts: {
		updateappvisible: {
			put: (req: IRequest<IApi['ContactAppVisibleRequest']>) =>
				fetchy<IRequest>('/api/contacts/updateappvisible', 'put', req),
		},
		searchcontacts: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/contacts/searchcontacts', 'get', req),
		},
		updatecontact: {
			put: (req: IRequest) =>
				fetchy<IRequest>('/api/contacts/updatecontact', 'put', req),
		},
		getmanuallyaddedcontacts: {
			get: (req?: IRequest) =>
				fetchy<IRequest>(
					'/api/contacts/getmanuallyaddedcontacts',
					'get',
					req
				),
		},
	},
	dfdg: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IApi['ActivitiesContainer']>(
					'/api/dfdg/activities',
					'get',
					req
				),
		},
		codelists: {
			get: (req?: IRequest) =>
				fetchy<IApi['GetInterviewTypeIdentifierCodeListResponse']>(
					'/api/dfdg/codelists',
					'get',
					req
				),
		},
	},
	dialogintegration: {
		post: (req: IRequest) =>
			fetchy<IRequest>('/api/dialogintegration', 'post', req),
		data: {
			post: (req: IRequest<IApi['DialogIntegrationCitizenRequest']>) =>
				fetchy<IRequest>('/api/dialogintegration/data', 'post', req),
		},
	},
	dubu: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/dubu/activities', 'get', req),
		},
	},
	findcitizen: {
		bycpr: {
			post: (req: IRequest<IApi['CitizenDataByCprRequest']>) =>
				fetchy<IApi['FindCitizenResponse']>(
					'/api/findcitizen/bycpr',
					'post',
					req
				),
		},
		byid: {
			post: (req: IRequest<IApi['CitizenDataByIdRequest']>) =>
				fetchy<IApi['FindCitizenResponse']>(
					'/api/findcitizen/byid',
					'post',
					req
				),
		},
	},
	kui: {
		planpdf: {
			choices: {
				get: (req?: IRequest) =>
					fetchy<IApi['PlanPdfChoices']>(
						'/api/kui/planpdf/choices',
						'get',
						req
					),
			},
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/kui/planpdf', 'get', req),
		},
		testplanpdf: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/kui/testplanpdf', 'get', req),
		},
		planworddocument: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/kui/planworddocument', 'get', req),
		},
	},
	lifesituation: {
		get: (req?: IRequest) =>
			fetchy<IRequest>('/api/lifesituation', 'get', req),
		post: (req: IRequest<IApi['SaveCommonDescriptionRequest']>) =>
			fetchy<IRequest>('/api/lifesituation', 'post', req),
		put: (req: IRequest<IApi['UpdateCommonDescriptionRequest']>) =>
			fetchy<IRequest>('/api/lifesituation', 'put', req),
		docx: {
			post: (
				req: IRequest<IApi['DownloadLifeSituationDocumentRequest']>
			) => fetchy<IRequest>('/api/lifesituation/docx', 'post', req),
		},
	},
	messagewall: {
		conversation: {
			post: (req: IRequest<IApi['CreateConversationRequest']>) =>
				fetchy<IRequest>('/api/messagewall/conversation', 'post', req),
			put: (req: IRequest<IApi['ConversationRecipientsRequest']>) =>
				fetchy<IRequest>('/api/messagewall/conversation', 'put', req),
		},
		reply: {
			post: (req: IRequest<IApi['CreateReplyRequest']>) =>
				fetchy<IRequest>('/api/messagewall/reply', 'post', req),
		},
		get: (req?: IRequest) =>
			fetchy<IRequest>('/api/messagewall', 'get', req),
	},
	momentum: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IApi['ActivitiesContainer']>(
					'/api/momentum/activities',
					'get',
					req
				),
		},
	},
	nexus: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IApi['ActivitiesContainer']>(
					'/api/nexus/activities',
					'get',
					req
				),
		},
	},
	pdf: {
		create: {
			post: (req: IRequest<IApi['PdfRequest']>) =>
				fetchy<IRequest>('/api/pdf/create', 'post', req),
		},
	},
	pushnotifications: {
		tonotify: {
			get: (req?: IRequest) =>
				fetchy<IRequest>('/api/pushnotifications/tonotify', 'get', req),
		},
		events: {
			post: (req: IRequest) =>
				fetchy<IRequest>('/api/pushnotifications/events', 'post', req),
		},
		tasks: {
			post: (req: IRequest) =>
				fetchy<IRequest>('/api/pushnotifications/tasks', 'post', req),
		},
		fcmmobileidstoremove: {
			get: (req?: IRequest) =>
				fetchy<IRequest>(
					'/api/pushnotifications/fcmmobileidstoremove',
					'get',
					req
				),
		},
	},
	status: {
		get: (req?: IRequest) => fetchy<IRequest>('/api/status', 'get', req),
		post: (req: IRequest<IApi['EventStatusRequest']>) =>
			fetchy<IApi['EventStatus']>('/api/status', 'post', req),
	},
	system: {
		get: (req?: IRequest) =>
			fetchy<IApi['UserConfiguration']>('/api/system', 'get', req),
	},
	unoung: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IApi['ActivitiesContainer']>(
					'/api/unoung/activities',
					'get',
					req
				),
		},
		kuidata: {
			get: (req?: IRequest) =>
				fetchy<IApi['KuiData']>('/api/unoung/kuidata', 'get', req),
		},
	},
	userconfiguration: {
		get: (req?: IRequest) =>
			fetchy<IRequest>('/api/userconfiguration', 'get', req),
	},
	uvvej: {
		activities: {
			get: (req?: IRequest) =>
				fetchy<IApi['ActivitiesContainer']>(
					'/api/uvvej/activities',
					'get',
					req
				),
		},
		kuidata: {
			get: (req?: IRequest) =>
				fetchy<IApi['KuiData']>('/api/uvvej/kuidata', 'get', req),
		},
		kuieducationinfo: {
			get: (req?: IRequest) =>
				fetchy<IApi['KuiEducationInfo']>(
					'/api/uvvej/kuieducationinfo',
					'get',
					req
				),
		},
	},
};

export type IApiList = typeof apiList;
