import { IGetHashAndQueryRes } from './types';

export function getHashAndQuery(paths: string[]): IGetHashAndQueryRes {
	let hash = '';
	let query = '';
	const l = paths.length;
	let lastPath = !!l ? paths[l - 1] : '';

	const hashIndex = lastPath.indexOf('#');
	const hashValid = hashIndex >= 0;
	const queryIndex = lastPath.indexOf('?=');
	const queryValid = queryIndex >= 0;
	const hasDirtyLastPath = hashIndex === 0 || queryIndex === 0;

	if (hashValid && queryValid) {
		const allLastParts = lastPath
			.split(/[#|?=]/)
			.filter(
				(val: string, index: number) => !!val.length || index === 0
			);

		if (queryIndex < hashIndex) {
			[lastPath, query, hash] = allLastParts;
		} else {
			[lastPath, hash, query] = allLastParts;
		}
	} else if (queryValid) {
		[lastPath, query] = lastPath.split('?=');
	} else if (hashValid) {
		[lastPath, hash] = lastPath.split('#');
	}

	paths[l - 1] = lastPath;

	if (hasDirtyLastPath) {
		paths = paths.slice(0, l - 1);
	}

	return { paths, hash, query };
}
