/* eslint-disable no-nested-ternary */
import {
	format as dateFnsFormat,
	isDate,
	isBefore,
	isAfter,
	isSameDay,
	isValid,
	addDays as dateFnsAddDays,
	isSameSecond as dateFnsIsSameSecond,
	subDays,
	startOfWeek,
	endOfWeek,
	startOfMonth as dateFnsStartOfMonth,
	endOfMonth as dateFnsEndOfMonth,
	addMonths as dateFnsAddMonths,
	subMonths as dateFnsSubMonths,
	isSameMonth as dateFnsIsSameMonth,
	isToday as dateFnsIsToday,
	parse as dateFnsParse,
	getDay,
	isSameYear,
	differenceInDays,
	differenceInYears,
} from 'date-fns';
import { da } from 'date-fns/locale';
import { IDate, ICitizenState } from 'api/types';
import { DateLocalizer } from 'react-big-calendar';

type DateMess = Date | string | null | undefined;
type DateFnsOptions = {
	locale?: Locale;
	// eslint-disable-next-line no-magic-numbers
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	firstWeekContainsDate?: number;
	useAdditionalWeekYearTokens?: boolean;
	useAdditionalDayOfYearTokens?: boolean;
};

/**
 * Brug ikke denne. Brug hellere formatDate
 */
const format = (date: Date, format: string, options?: DateFnsOptions) => {
	return dateFnsFormat(date, format, {
		...(options || {}),
		locale: da,
		weekStartsOn: 1,
	});
};

export {
	format,
	startOfWeek,
	isValid,
	da,
	getDay,
	isAfter,
	dateFnsIsToday,
	differenceInDays,
	differenceInYears,
	isSameDay,
};

export type DateFormatType =
	| 'time'
	| 'date'
	| 'day'
	| 'fullMonthYear'
	| 'weekYear'
	| 'shortDate'
	| 'datePicker'
	| 'longDate'
	| 'longDateWithTime'
	| 'longDateWithoutCurrentYear'
	| 'longDateWithTimeWithoutCurrentYear';

const nowInMs = new Date().getTime();

// Da backend p.t. ikke understøtter null, var datoerne sat enten langt i fortiden, eller langt i fremtiden -.-
const LATEST_DATE = '2099-01-01';

export const MAX_END_TIME_DATE = new Date(LATEST_DATE);

const MAX_END_TIME = MAX_END_TIME_DATE.getTime();

/*
	Remove this hack once backend can handle Nullable enddates.
	Currently, we send in year 2099 as a mock enddate, and year 1 as startDate - And we don't want that showing in the UI
*/
function checkIfDateIsOutOfScope(date: Date): boolean {
	const dateInMs = date.getTime();
	const fiftyYearsInMs = 1576800000000;
	const hundredYearsInMs = 3153600000000;
	const dateIsMoreThanFiftyYearsInTheFuture =
		dateInMs > nowInMs + fiftyYearsInMs;
	const dateIsLessThanHundredYearsInThePast =
		dateInMs < nowInMs - hundredYearsInMs;

	return (
		dateIsMoreThanFiftyYearsInTheFuture ||
		dateIsLessThanHundredYearsInThePast
	);
}

/*
	https://date-fns.org/v2.16.1/docs/format
	https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
*/
export function getFormatStrType(formatType?: DateFormatType): string {
	switch (formatType) {
		case 'time':
			return 'H:mm';
		case 'longDateWithTime':
			return 'd. MMM yyyy H:mm';
		case 'longDateWithTimeWithoutCurrentYear':
			return 'd. MMM H:mm';
		case 'longDateWithoutCurrentYear': {
			return 'd. MMM';
		}
		case 'fullMonthYear': {
			return 'MMMM yyyy';
		}
		case 'weekYear': {
			return 'I yyyy';
		}
		case 'day': {
			return 'EEEE';
		}
		case 'date': {
			return 'd';
		}
		case 'shortDate': {
			return 'yyyy-MM-dd'; // Do NOT change this. Otherwise, this will mess up some api requests (Yes, I'm cursing too).
		}
		case 'datePicker': {
			return 'dd-MM-yyyy';
		}
		case 'longDate':
		default:
			return 'd. MMM yyyy';
	}
}

export const LENGTH_OF_FORMATTED_SHORT_DATE = 10;

export function getAsDate(date: DateMess): Date {
	if (date && typeof date === 'string') {
		return new Date(date);
	}

	return date as Date;
}

export function formatDate(
	date?: DateMess,
	dateFormat?: DateFormatType
): string {
	if (!date) return '';

	const dateObject = getAsDate(date);

	if (!isDate(dateObject) || checkIfDateIsOutOfScope(dateObject)) {
		return '';
	}

	const formatStr = getFormatStrType(dateFormat);

	return format(dateObject, formatStr, { locale: da });
}

export const getAsValidDateOrUndefined = (
	date?: DateMess
): Date | undefined => {
	if (!date) return;

	if (typeof date === 'string') {
		return new Date(date);
	}

	return date;
};

export function getIsToday(dateMess: DateMess): boolean {
	const date = getAsValidDateOrUndefined(dateMess);

	return dateFnsIsToday(date);
}

const isSameDayEnhanced = (
	startDate?: DateMess,
	endDate?: DateMess
): boolean => {
	const start = getAsValidDateOrUndefined(startDate);
	const end = getAsValidDateOrUndefined(endDate);

	if (!start || !end) {
		return false;
	}

	return isSameDay(start, end);
};

export function parse(dateString: string, format: string) {
	return dateFnsParse(dateString, format, new Date(), {
		locale: da,
	});
}

// For at undgå, at ende i samme situation som med moment. Håndterer vi alt date-fns herfra. Så er det lettere at skifte ud, hvis behovet opstår.
export const dateIsBefore = isBefore;
export const dateIsAfter = isAfter;
export const dateIsSame = isSameDayEnhanced;
export const addDays = dateFnsAddDays;
export const subtractDays = subDays;
export const isSameSecond = dateFnsIsSameSecond;
export const startOfMonth = dateFnsStartOfMonth;
export const endOfMonth = dateFnsEndOfMonth;
export const addMonths = dateFnsAddMonths;
export const subMonths = dateFnsSubMonths;
export const isToday = dateFnsIsToday;
export const isSameMonth = dateFnsIsSameMonth;

export function isSameOrBefore(date: Date, anotherDate: Date): boolean {
	return isSameDay(date, anotherDate) || isBefore(date, anotherDate);
}

export function isSameOrAfter(date: Date, anotherDate: Date): boolean {
	return isSameDay(date, anotherDate) || isAfter(date, anotherDate);
}

export function getTimeSpanText(start: Date, end: Date): string {
	if (start && end) {
		return `${formatDate(start, 'time')} – ${formatDate(end, 'time')}`;
	}

	return '';
}

export function getDateTimeSpan(start: Date, end?: Date): string {
	const from = !!start ? formatDate(start, 'longDateWithTime') : '';
	const to = !!end
		? !isSameDay(start, end)
			? formatDate(end, 'longDateWithTime')
			: formatDate(end, 'time')
		: '';

	return `${from} – ${to}`;
}

export function getDateTimeSpanForCalendar(
	start?: DateMess,
	end?: DateMess
): string {
	const startValid = getAsValidDateOrUndefined(start);

	if (!startValid) {
		return '';
	}

	const endValid = getAsValidDateOrUndefined(end);
	const sameDay = endValid && isSameDay(startValid, endValid);
	const format = sameDay ? 'time' : 'shortDate';

	const from = formatDate(startValid, format);

	// Hvis det er et tidspunkt, skal vi ikke have en bindestreg.
	// Men det ønsker vi hvis det er en dato, så at man kan se at der ikke er sat en slutdato.
	if (!end && format === 'time') {
		return from;
	}

	const to = formatDate(endValid, format);

	return `${from} – ${to}`;
}

export function getDateSpan(start: Date, end?: Date | null): string {
	const from = !!start ? formatDate(start, 'longDate') : '';
	const to =
		!!end && !isSameDay(start, end) ? formatDate(end, 'longDate') : '';

	return to ? `${from} – ${to}` : from;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortByTimeFromKey<T extends Record<string, any>>(
	list: T[],
	key: string
): T[] {
	return list.sort((a, b) => {
		const A: Date = a[key];
		const B: Date = b[key];

		if (!isDate(A)) {
			return -1;
		}

		if (!isDate(B)) {
			return 1;
		}

		return isBefore(A, B) ? -1 : 1;
	});
}

export const convertToDateObject = (
	date: Record<keyof IDate, Date | string>
): IDate => {
	return {
		currentMinDate: getAsDate(date.currentMinDate),
		currentMaxDate: getAsDate(date.currentMaxDate),
		maxPastDateToFetch: getAsDate(date.maxPastDateToFetch),
		maxFutureDateToFetch: getAsDate(date.maxFutureDateToFetch),
	};
};

export const getNewDateObject = (currentDate: Date): IDate => {
	const start = dateFnsStartOfMonth(currentDate);
	const end = dateFnsEndOfMonth(currentDate);

	return {
		currentMinDate: start,
		currentMaxDate: end,
		maxPastDateToFetch: start,
		maxFutureDateToFetch: end,
	};
};

export const convertToDateStringObject = (
	date: Record<keyof IDate, Date | string>
): ICitizenState['date'] => {
	return {
		currentMinDate:
			(date.currentMinDate as Date)?.toISOString?.() ||
			(date.currentMinDate as string),
		currentMaxDate:
			(date.currentMaxDate as Date)?.toISOString?.() ||
			(date.currentMaxDate as string),
		maxPastDateToFetch:
			(date.maxPastDateToFetch as Date)?.toISOString?.() ||
			(date.maxPastDateToFetch as string),
		maxFutureDateToFetch:
			(date.maxFutureDateToFetch as Date)?.toISOString?.() ||
			(date.maxFutureDateToFetch as string),
	};
};

export function getStartOfWeek(date: Date) {
	return startOfWeek(date, { weekStartsOn: 1 });
}

export function getEndOfWeek(date: Date) {
	return endOfWeek(date, { weekStartsOn: 1 });
}

function getCalendarDateRangeFormat(
	{ start, end }: { start: Date; end: Date },
	culture: string,
	localizer: DateLocalizer
) {
	if (isSameYear(start, end)) {
		if (isSameMonth(start, end)) {
			return (
				localizer.format(start, 'd', culture) +
				' — ' +
				localizer.format(end, 'd. MMMM yyyy', culture)
			);
		}

		return (
			localizer.format(start, 'd. MMMM', culture) +
			' — ' +
			localizer.format(end, 'd. MMMM yyyy', culture)
		);
	}

	return (
		localizer.format(start, 'd. MMMM yyyy', culture) +
		' — ' +
		localizer.format(end, 'd. MMMM yyyy', culture)
	);
}

// http://jquense.github.io/react-big-calendar/examples/index.html#prop-formats
// https://date-fns.org/v2.23.0/docs/format
export const calendarFormats = {
	agendaHeaderFormat: getCalendarDateRangeFormat,
	dayRangeHeaderFormat: getCalendarDateRangeFormat,
	monthHeaderFormat: 'd. MMMM yyyy',
	dayHeaderFormat: 'd. MMMM yyyy',
	dayFormat: 'd. EEEE',
};

export function parseShortDate(shortDate: string) {
	const shortDateFormat = getFormatStrType('shortDate');

	return parse(shortDate, shortDateFormat); // fallback til i dag
}

/**
 * getCachedDateKeyForCalendar bruges i kalenderen til generering af keys, som validerer
 * om den pågældende måned allerede er indlæst/cached
 */
export function getCachedDateKeyForCalendar(currentDate: Date) {
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth();

	return `${year}-${month}`;
}

export function getDateTextForPeriod(
	start: string | Date,
	end?: DateMess,
	showTextIfNoEndDate = true
): string {
	const startDate = getAsDate(start);
	let endDate = end ? getAsDate(end) : null;

	const dates = [];

	if (endDate && endDate.getTime() === MAX_END_TIME) {
		endDate = null;
	}

	if (startDate) {
		dates.push(formatDate(startDate, 'longDate'));
	}

	if (endDate && !isSameDay(startDate, endDate)) {
		dates.push(formatDate(endDate, 'longDate'));
	} else if (startDate && !endDate && showTextIfNoEndDate) {
		dates.push('ingen slutdato');
	}

	return dates.join(' – ');
}

/**
 * HACK! Fjerner dato hvis den virker urealistisk.
 * P.t. vil vi ikke have, at år 2099 kommer som erstatning i UI'en - for ja, det var brugt i stedet for null -.-
 *
 * TODO: Fjern denne når backend understøtter nullable enddates
 */
export function getAsRealisticDate(input?: DateMess): Date | undefined {
	if (!input) {
		return;
	}

	const date = getAsDate(input);
	const dateInMs = date.getTime();
	const nowInMs = new Date().getTime();
	const fiftyYearsInMs = 1576800000000;
	const hundredYearsInMs = 3153600000000;
	const dateIsMoreThanFiftyYearsInTheFuture =
		dateInMs > nowInMs + fiftyYearsInMs;
	const dateIsLessThanHundredYearsInThePast =
		dateInMs < nowInMs - hundredYearsInMs;

	if (
		!(
			dateIsMoreThanFiftyYearsInTheFuture ||
			dateIsLessThanHundredYearsInThePast
		)
	) {
		return date;
	}
}

export function getAge(date: Date) {
	const now = new Date();
	const yearBorn = date.getFullYear();
	const yearToday = now.getFullYear();
	const birthDayThisYear = new Date(date);

	birthDayThisYear.setFullYear(yearToday);

	let age = yearToday - yearBorn;

	if (isAfter(birthDayThisYear, now)) {
		age -= 1;
	}

	return age;
}

interface IFilterByToday {
	startTime?: string;
	endTime?: string;
	dayOffset?: number;
	includeIfNoEndtime?: boolean;
}

export function filterEndtimeByToday({
	startTime,
	endTime,
	dayOffset = 0,
	includeIfNoEndtime,
}: IFilterByToday): boolean {
	if (!startTime) return false;

	if (!endTime) return !!includeIfNoEndtime;

	const endPlusOffSet = addDays(getAsDate(endTime), dayOffset);

	return isSameOrBefore(new Date(), endPlusOffSet);
}
