import { getHeader } from 'api/utils/headers';
import { silentRefresh } from 'projects/worktogether/login/auth';
import { logApi, logException } from 'utils/logging';
import { IRequest, IApiResponseObject } from './types';
import { officeDocx } from './utils/header-types';

const apiUrl = process.env.REACT_APP_MICROSERVICE_URL as string; // Den er sgu ikke undefined :(
const httpNoData = 204;
const httpUnauthorized = 401;

/**
 * Hvis hele url stien bliver sendt med, fx. som ved system.connectorUrl, trimmer vi apiUrl fra, så vi kun har rest.
 * Ellers skulle vi sende flere parametre med ind, og det gider vi ikke. Der er nok rod i den oprindelige arkitektur.
 */
function trimRestUrl(url: string): string {
	return url.replace(apiUrl, '');
}

// Because it's such a catchy name and it fetches something!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchy<IRes extends IRequest<any>>(
	restUrl: string,
	method: string,
	req: IRequest
): Promise<IApiResponseObject<IRes>> {
	const trimmedUrl = trimRestUrl(restUrl);
	const { queryString, ...requestInit } = await getHeader(method, req);
	const url = `${apiUrl}${trimmedUrl}${queryString}`;

	// MOCK DATA
	if (process.env.NODE_ENV === 'development') {
		const mockId =
			// @ts-ignore - It's only mock - we don't really care about the headers.
			req?.cpr || req?.id || requestInit?.headers?.get?.('citizenmockid');
		const { mockData } = await import('./mock-data');

		if (mockData[mockId]) {
			const mockPath = restUrl.replace(/^\/api\/|\?.*$/g, '').split('/');

			const data = mockPath.reduce((result, currentKey) => {
				// @ts-ignore
				return result?.[currentKey];
			}, mockData[mockId]);

			return {
				noData: !!data,
				responseStatusCode: 200,
				isSuccess: true,
				// @ts-ignore
				data,
			};
		}
	}

	logApi(url, requestInit);

	const response: Response = await fetch(url, requestInit).catch((e) => {
		logException(e);

		// eslint-disable-next-line no-console
		console.error(e.message);

		return e;
	});

	let responseData;
	let responseBlobUrl;

	if (req?.blobType === 'officeDocx' || req?.blobType === 'application/pdf') {
		const blob = new Blob([await response.blob()], {
			type: req.blobType === 'officeDocx' ? officeDocx : req.blobType,
		});

		responseData = blob;

		responseBlobUrl = URL.createObjectURL(blob);
	} else {
		// We assume it's JSON
		try {
			responseData = await response.json();
		} catch (error) {
			logException(error);
		}
	}

	const isSuccess = response?.status?.toString()[0] === '2';

	if (response.ok) {
		return {
			noData: response.status != httpNoData && !responseData, // We can't trust the backend -.-
			responseStatusCode: response.status,
			isSuccess,
			data: responseData,
			blobUrl: responseBlobUrl,
		};
	}
	// Eller vi kunne håndterer fejl her også.

	if (response.status === httpUnauthorized) {
		silentRefresh(); // Will log out if token is expired
	}

	const { ExceptionType, DisplayMessage, Message, description } =
		responseData || {};

	return {
		noData: true,
		responseStatusCode: response.status,
		exceptionType: ExceptionType,
		isSuccess,
		data: responseData,
		errorMessage:
			DisplayMessage || Message || description?.join?.(', ') || '',
	};
}
