import {
	ApplicationInsights,
	IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { getEnvironmentSettings } from 'environments';

const environment = getEnvironmentSettings();
const apiUrl = new URL(environment.apiUrl);
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: environment.appInsights.instrumentationKey,
		enableCorsCorrelation: true,
		correlationHeaderDomains: [apiUrl.hostname],
	},
});

export function initApplicationInsights() {
	appInsights.loadAppInsights();
}

export function logPageView(name: string, uri: string): void {
	appInsights.trackPageView({
		name,
		uri,
	});
}

export function logApi(apiUrl: string, properties?: Record<string, any>) {
	appInsights.trackEvent({
		name: apiUrl,
		properties,
	});
}

export function logException(exception: Error, severityLevel?: number): void {
	const req: IExceptionTelemetry = { exception, severityLevel };

	appInsights.trackException(req);
}
