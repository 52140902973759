import { IDate } from 'api/types';
import { formatDate } from 'utils/datetime';

export const generateWorkTogetherURL = (citizenId?: string): string | null => {
	if (!citizenId || citizenId === '') {
		return null;
	}

	return (
		process.env.REACT_APP_WORKTOGETHER_URL + `/auth/citizenId/${citizenId}`
	);
};

export const getURLTimeRange = (date: IDate): string => {
	const min = formatDate(date.currentMinDate, 'shortDate');
	const max = formatDate(date.currentMaxDate, 'shortDate');

	return `?dateFrom=${min}&dateTo=${max}`;
};
