import { getCharDataArray } from './get-char-data-array';

export function sortDanishString(
	a: string,
	b: string,
	caseSensitive: boolean
): number {
	const aCharDataArray = getCharDataArray(a);
	const bCharDataArray = getCharDataArray(b);

	const arrayLength = aCharDataArray?.length || 0;

	for (let i = 0; i < arrayLength; i++) {
		const {
			baseCharCode: aBaseCharCode,
			isAccent: aIsAccent,
			isUppercase: aIsUppercase,
		} = aCharDataArray?.[i] || {};

		const {
			baseCharCode: bBaseCharCode,
			isAccent: bIsAccent,
			isUppercase: bIsUppercase,
		} = bCharDataArray?.[i] || {};

		if (aBaseCharCode === bBaseCharCode) {
			if (aIsAccent !== bIsAccent) {
				return aIsAccent ? 1 : -1;
			}

			if (caseSensitive && aIsUppercase !== bIsUppercase) {
				return aIsUppercase ? 1 : -1;
			}
		} else {
			return aBaseCharCode - bBaseCharCode;
		}
	}

	return 0;
}
