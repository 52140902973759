import { addNewUniqueAdditionsdByID, sortContacts } from './activities';
import { IApi } from 'typings/api';
import { IAddActivitiesPayload, ICitizenState } from 'api/types';

export function setAdditionalActivitiesAndContacts<
	Payload extends IAddActivitiesPayload
>(state: ICitizenState, payload: Payload): void {
	if (!payload) return;

	const events = addNewUniqueAdditionsdByID<IApi['Activity']>(
		state.events || [],
		payload.events || [],
		true
	);

	const periods = addNewUniqueAdditionsdByID<IApi['Activity']>(
		state.periods || [],
		payload.periods || [],
		true
	);

	const contacts = addNewUniqueAdditionsdByID<IApi['Contact']>(
		state.contacts || [],
		payload.contacts || [],
		false
	);

	const notConnectedGoals = (state.notConnectedGoals || []).concat(
		payload.notConnectedGoals || []
	);

	sortContacts(contacts);

	state.notConnectedGoals = notConnectedGoals;
	state.events = events;
	state.periods = periods;
	state.contacts = contacts;
}
