/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
//import { isMoment } from 'moment';
import { sortDanishString } from './sort-danish-string';
import { emptyValue } from './empty-value';

interface IComparer {
	(props: {
		isDescending: boolean;
		name?: string;
		/** Forces nullable values to always be sorted last */
		keepNullLast?: boolean;
		caseSensitive?: boolean;
	}): (a: any, b: any) => number;
}

const nullableValues = ['', emptyValue, null, undefined];

const isNull = (value: any): boolean => nullableValues.includes(value);

/** Order: `''`, `emptyValue`, `null`, `undefined` */
const compareNullValues = (
	aValue: undefined | null | typeof emptyValue,
	bValue: undefined | null | typeof emptyValue
): 1 | 0 | -1 => {
	if (aValue === bValue) return 0;

	for (const nullableValue of nullableValues) {
		if (aValue === nullableValue) return -1;
		if (bValue === nullableValue) return 1;
	}

	return 0;
};

export const getComparer: IComparer =
	({ isDescending, name, keepNullLast, caseSensitive = true }) =>
		(a, b) => {
			const aValue = name ? a[name] : a;
			const bValue = name ? b[name] : b;

			if (keepNullLast) {
				if (isNull(aValue) && isNull(bValue))
					return compareNullValues(aValue, bValue);
				if (isNull(aValue)) return 1;
				if (isNull(bValue)) return -1;
			}

			const A = isDescending ? bValue : aValue;
			const B = isDescending ? aValue : bValue;

			if (isNull(A) && isNull(B)) return compareNullValues(A, B);
			if (isNull(A)) return 1;
			if (isNull(B)) return -1;

			const dataTypeA = typeof A;
			const dataTypeB = typeof B;

			/*if (isMoment(A) || isMoment(B)) {
			if (!A) return -1;
			else if (!B) return 1;

			if (typeof B.isBefore === 'function') {
				return B.isBefore(A) ? 1 : -1;
			}

			if (typeof A.isSameOrAfter === 'function') {
				return A.isSameOrAfter(B) ? 1 : -1;
			}

			return 0;
		}*/

			if (dataTypeA === 'number' && dataTypeB === 'number') {
				return A - B;
			}

			return sortDanishString(A, B, caseSensitive);
		};
