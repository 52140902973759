/** Returns array of unique items */
export function distinctArrayValues<T>(
	value: T,
	index: number,
	self: T[]
): boolean {
	return self.indexOf(value) === index;
}

export function distinctNonEmptyArrayValues<T>(
	value: T,
	index: number,
	self: T[]
): boolean {
	return !!value && distinctArrayValues(value, index, self);
}
